<template>
  <div id="chapterOutside">
    <div style="margin: 0 auto 74px">
      <TopOutside />
    </div>
    <div class="containInfo">
      <div class="list-flex">
        <img :src="queryData.imgVertical" alt="" />
        <div>
          <div class="text">{{ queryData.name }}</div>
          <div class="professorNames" style="margin-top: 43px">
            <img class="proImg" src="@/assets/Web/proImg.png" />
            <span>{{ queryData.professorName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="chapterList">
      <div class="catalogue">课程目录</div>
      <ul>
        <li
          v-for="(item, index) in chapterList"
          :key="index"
          @click="video(item.chapterVideoPath)"
        >
          <img :src="queryData.imgVertical" class="imgVertical" />
          <div>
            <div class="names">{{ item.name }}</div>
            <div class="professorName">
              时长：<span>{{ stohs(item.videoDuration) }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <FooterOutside />
    <el-dialog
      :visible.sync="dialogVisible"
      width="950px"
      :destroy-on-close="true"
    >
      <video
        :src="chapterVideoPath"
        controls
        disablePictureInPicture
        width="100%"
        height="500px"
      />
      <div class="dialog">
        <img
          class="dialogImg"
          src="@/assets/Web/cuo.png"
          @click="dialogImg()"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TopOutside from "@/views/Web/TopOutside.vue";
import FooterOutside from "@/views/Web/FooterOutside.vue";
import { getChapterOutside } from "@/api/Web.js";
import { stohs } from "@/utils/date.js";
import "./web.less";
export default {
  name: "chapterOutside",
  data() {
    return {
      page: 1,
      size: 1000,
      total: 0,
      stohs,
      chapterList: [],
      dialogVisible: false,
      chapterVideoPath: "",
      queryData: this.$route.query,
    };
  },
  components: {
    TopOutside,
    FooterOutside,
  },
  created() {
    this.chapterOutside();
  },
  methods: {
    dialogImg() {
      this.dialogVisible = false;
      this.chapterVideoPath = "";
    },
    video(val) {
      this.dialogVisible = true;
      this.chapterVideoPath = val;
    },
    async chapterOutside() {
      const info = {
        page: this.page,
        size: this.size,
        courseId: this.queryData.id,
      };
      const res = await getChapterOutside(info);
      const { data, success } = res.data;
      if (success) {
        const { records } = data;
        this.chapterList = records && records.length ? records : [];
        this.total = data.total;
      } else {
        this.chapterList = [];
        this.total = 0;
      }
    },
  },
};
</script>

<style lang='less' scoped>
#chapterOutside {
  background: #ffffff;
  color: #333333;

  .containInfo {
    width: 100%;
    height: 208px;
    border-radius: 1px;
    background: url("~@/assets/Web/bgImg.png") no-repeat center / cover;
    position: relative;
    .list-flex {
      width: 1200px;
      height: 204px;
      position: absolute;
      top: 40px;
      left: 50%;
      margin-left: -600px;
      background: #fff;
      display: flex;
      flex-wrap: nowrap;
      border-radius: 10px;
      box-shadow: 0px 2px 42px 0px rgba(209, 209, 209, 0.5);
      padding: 20px;
      img {
        width: 279px;
        height: 156px;
        border-radius: 10px;
        background-size: contain;
        margin-right: 20px;
      }
      .text {
        height: 20px;
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        margin-top: 16px;
        line-height: 30px;
      }
      .professorNames {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        margin-top: 28px;
        display: flex;
        justify-content: left;
        align-items: center;

        .proImg {
          width: 15px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  .chapterList {
    width: 1200px;
    margin: 65px auto 0;
    min-height: 65vh;
    .catalogue {
      color: #337b72;
      font-weight: 500;
      margin-left: 20px;
      margin-bottom: 8px;
    }
  }

  .dialog {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  /deep/.el-dialog__body {
    padding: 30px 20px 0;
  }
}
</style>
