import request from '@/utils/request'

// 对外课程列表
export function getCourseOutside(params) {
    return request({
        url: '/config/outer/courses',
        method: 'GET',
        params
    })
}

// 对外章节列表
export function getChapterOutside(params) {
    return request({
        url: '/config/outer/chapters',
        method: 'GET',
        params
    })
}