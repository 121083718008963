<template>
  <div class="FooterOutside">
    <footer>
      <div class="text">
        <span @click="link(1)">京 ICP 备 2023010124 号 - 1</span>
        <span style="margin: 0 39px" @click="link(2)">
          京公网安备11010502052392号
        </span>
        <span>技术支持：北京贝湾教育科技有限公司</span>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterOutside",
  methods: {
    link(val) {
      window.open(
        val === 1
          ? "https://beian.miit.gov.cn/#/Integrated/index"
          : "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052392"
      );
    },
  },
};
</script>

<style lang='less' scoped>
footer {
  height: 43px;
  line-height: 43px;
  background: #ebebeb;
  text-align: center;
  color: rgba(80, 80, 80, 1);
  font-size: 12px;
  margin-top: 25px;

  .text {
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #505050;
  }
}
</style>