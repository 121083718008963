<template>
  <div class="Top">
    <div class="flex">
      <img class="logo" src="@/assets/Web/logo.png" @click="course" />
      <div style="display: flex" @click="course">
        <div class="line"></div>
        <div class="item">
          <span>COURSE</span>
          <span>课程</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopOutside",
  methods: {
    course() {
      this.$router.push({ path: "/courseOutside" });
    },
  },
};
</script>

<style lang='less' scoped>
.Top {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 74px;
  background: #ffffff;
  display: flex;
  justify-content: left;
  align-items: center;
  .flex {
    width: 1200px;
    margin: 0 auto;
  }
  .logo {
    width: 84px;
    height: 39px;
    margin-right: 50px;
  }
  .item {
    display: flex;
    flex-direction: column;
    padding: 0 70px 0 8px;
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    text-align-last: left;
  }
  .line {
    display: block;
    width: 8px;
    height: 40px;
    background: #337b72;
    border-radius: 5px;
  }
}
</style>